import React from "react";
import { Content, Title, Header, Subheader, Link } from './DRKStyle';


export default function HomePage() {
  return (
    <Content>
      <Title>DRK Barmbek</Title>
      <p>Willkommen auf den Webseiten der Bereitschaft Barmbek im DRK Ortsverein Barmbek-Uhlenhorst.</p>
    </Content>
  );
}
